import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../setup'
import {UserModel} from '../../modules/auth/models/UserModel'
import {ModifiedCompetencyItem} from '../types/careerPageTypes'

interface props {
  category: string
  competencies: ModifiedCompetencyItem[]
}

const CompetencyGraph: FC<props> = ({category, competencies}) => {
  const intl = useIntl()

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const pages = JSON.parse(user.company?.companyConfig?.pages)
  const graphKey = pages?.web?.competency

  return (
    <div className='competencyGraph'>
      <h2 className='competency-title'>{category}</h2>
      <div className='chart'>
        {competencies.map((competency: ModifiedCompetencyItem) => (
          <div className='competency' key={competency.competencyId}>
            <div className='requiredLevel' style={{width: `${competency.requiredLevel}%`}} />
            <div className='actualLevel' style={{width: `${competency.actualLevel}%`}} />
            <div className='levelName'>{competency.competencyName}</div>
          </div>
        ))}
      </div>
      <div className='levelLabels'>
        <p style={{width: '20px'}}></p>
        <p>{graphKey.Fundamental}</p>
        <p>{graphKey.Proficient}</p>
        <p> {graphKey.Advanced}</p>
        <p>{graphKey.Mastery}</p>
        {/* <p>{intl.formatMessage({id: 'POPLEADS.FUNDAMENTAL'})}</p>
        <p>{intl.formatMessage({id: 'POPLEADS.PROFICIENT'})}</p>
        <p>{intl.formatMessage({id: 'POPLEADS.ADVANCED'})}</p>
        <p>{intl.formatMessage({id: 'POPLEADS.MASTERY'})}</p> */}
      </div>
    </div>
  )
}

export default CompetencyGraph
