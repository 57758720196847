import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers/components/KTSVG'
import {CourseBox} from '../components/courseBox'
import {getDynamicValue} from '../functions/util'
import {post} from '../networking/requestService'
import {AllCoursesNoCourse} from './AllCoursesNoCourse'
interface AllCoursesProps {
  campaignList: any
  sortAndOrder: any
}

const AllCourses: FC<AllCoursesProps> = (props: AllCoursesProps) => {
  const intl = useIntl()
  const [courses, setCourses] = useState<any>([])
  const [myCourses, setMyCourses] = useState<any>(null)

  useEffect(() => {
    const requestObj = {
      fn: 'getProfileCampaigns',
    }
    post(requestObj).then(({data: {campaignCurrentList, campaignHistoryList}}) => {
      setMyCourses({campaignCurrentList, campaignHistoryList})
    })
  }, [courses])

  const isCompleted = (course: any) => {
    if (myCourses) {
      const history = myCourses.campaignHistoryList
      for (let i = 0; i < history.length; i++) {
        if (history[i].id === course.id) return true
      }
    }
    return false
  }

  const isStarted = (course: any) => {
    if (myCourses) {
      const current = myCourses.campaignCurrentList
      for (let i = 0; i < current.length; i++) {
        if (current[i].id === course.id) return true
      }
    }
    return false
  }

  useEffect(() => {
    setCourses(props.campaignList)
  }, [props.campaignList])
  return (
    <div className='row allCourses'>
      {/* <div className='d-flex w-100 row'>
                <div className="col-12 col-lg-6">
                    <span className='allCoursesTitle'>Explore All Courses</span>
                </div>
                <div className="col-12 col-lg-6 allCoursesIcons">
                    <div className='mt-10 mt-lg-0 allCoursesIcons_inner'>
                        <span>
                            <KTSVG
                                path='/media/svg/popleads/grid.svg'
                                className='svg-icon-1 grid'
                            />
                        </span>
                        <span>
                            <KTSVG
                                path='/media/svg/popleads/list.svg'
                                className='svg-icon-1'
                            />
                        </span>
                    </div>
                </div>
            </div> */}
      <div className='section w-100 '>
        <div className='container  d-flex justify-content-between'>
          <div className='title d-flex align-items-center'>
            <KTSVG path='/media/svg/popleads/play.svg' className='svg-icon-1 play' />
            {intl.formatMessage({id: 'POPLEADS.E-LEARNING_COURSE'})}
          </div>
          <select
            className='d-none d-sm-block sortSelect'
            onChange={(change: any) => {
              // console.log("change: ", change);
              change = change.target.value
              if (change === 'startDate') {
                props.sortAndOrder('basTarih', 'asc')
              } else if (change === 'asc') {
                props.sortAndOrder('ad', 'asc')
              } else if (change === 'desc') {
                props.sortAndOrder('ad', 'desc')
              }
            }}
          >
            <option value='startDate'>{intl.formatMessage({id: 'POPLEADS.START_DATE'})}</option>
            <option value='asc'>{intl.formatMessage({id: 'POPLEADS.A-Z_SORTING'})}</option>
            <option value='desc'>{intl.formatMessage({id: 'POPLEADS.Z-A_SORTING'})}</option>
          </select>
        </div>
      </div>
      <select
        className='d-block d-sm-none mt-5 sortSelectMobile'
        onChange={(change: any) => {
          // console.log("change: ", change);
          change = change.target.value
          if (change === 'startDate') {
            props.sortAndOrder('basTarih', 'asc')
          } else if (change === 'asc') {
            props.sortAndOrder('ad', 'asc')
          } else if (change === 'desc') {
            props.sortAndOrder('ad', 'desc')
          }
        }}
      >
        <option value='startDate'>{intl.formatMessage({id: 'POPLEADS.START_DATE'})}</option>
        <option value='asc'>{intl.formatMessage({id: 'POPLEADS.A-Z_SORTING'})}</option>
        <option value='desc'>{intl.formatMessage({id: 'POPLEADS.Z-A_SORTING'})}</option>
      </select>
      {courses.length === 0 ? (
        <div className='allCoursesNoCourse'>
          <AllCoursesNoCourse />
        </div>
      ) : (
        <div className='allCoursesGrid'>
          {courses.map((course: any) => (
            <CourseBox
              key={course.id}
              title={course.ad}
              subtitle={getDynamicValue(course.columnsList, 'Short Description')}
              hour={getDynamicValue(course.columnsList, 'Video Hours')}
              lesson={getDynamicValue(course.columnsList, 'Number of Lectures')}
              primary={
                (course.basTarih !== null && new Date() > new Date(course.basTarih)) ||
                course.basTarih === null
              }
              primaryText={
                isStarted(course)
                  ? intl.formatMessage({id: 'POPLEADS_RESUME_COURSE'})
                  : intl.formatMessage({id: 'POPLEADS.GO_TO_COURSE'})
              }
              imgUrl={course.imgURL}
              basTarih={course.basTarih}
              id={course.id}
              allCourses={true}
              isCompleted={isCompleted(course)}
              completedText={intl.formatMessage({id: 'POPLEADS_COURSE_COMPLETED'})}
              link={course.link}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export {AllCourses}
