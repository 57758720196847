/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, Fragment, useContext } from 'react'
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { languages } from '../../../_metronic/partials/layout/header-menus/Languages'
import { useLang } from '../../../_metronic/i18n/Metronici18n'
import { ConfigDataContext } from '../../modules/context/configDataContext'
// import FAQ from './faq'


interface MyCourseProps {
    info: any;
    courseLanguage: any,
}
const MyCourseDetailOverview: FC<MyCourseProps> = (props: MyCourseProps) => {
    // const [navbar, setNavbar] = useState('overview');

    const intl = useIntl()

    let isEmpty = true;
    const lang = useLang()
    const currentLanguage = languages.find((x) => x.lang === lang)
    if (currentLanguage?.lang !== "en") {
        for (let index = 0; index < props.info.length; index++) {
            const element = props.info[index];
            if (currentLanguage && element.translations && element.translations[currentLanguage?.lang]) {
                isEmpty = false;
            }
        }
    }

    const {configData} = useContext(ConfigDataContext)
    const imageUrl = configData ? configData.imageUrls?.courseDetailPageImg : ""

    return (
        <div className='myCourse_overview row'>
            <div className='col-lg-6'>
                {/* {console.log("current Language", currentLanguage)} */}
                {(props.info && props.info.length > 0) ?
                    props.info.map((infoObject: any, index: number) => {
                        return <Fragment key={`my__course__${index}`}>
                            {currentLanguage && infoObject.translations && infoObject.translations[currentLanguage?.lang] && currentLanguage.lang !== "en" && 
                                <div className='myCourse_courseOverview'>
                                    <h2 className='long'>{infoObject.translations[currentLanguage?.lang].name}</h2>
                                    <div 
                                        className="" 
                                        dangerouslySetInnerHTML={{ __html: infoObject.translations[currentLanguage?.lang].description }}
                                        style={{direction: props.courseLanguage === "Arabic" ? "rtl" : "ltr"}}>
                                    </div>
                                </div>
                            }

                            {currentLanguage && currentLanguage.lang === "en" &&
                                <div className='myCourse_courseOverview'>
                                    <h2 className='long'>{infoObject.name}</h2>
                                    <div 
                                        className="" 
                                        dangerouslySetInnerHTML={{ __html: infoObject.description }} 
                                        style={{direction: props.courseLanguage === "Arabic" ? "rtl" : "ltr"}}> 
                                    </div>
                                </div>
                            }
                        </Fragment>
                    }
                    )
                    :
                    <div className='myCourse_courseOverview'>
                        <h2 className='long'>{intl.formatMessage({ id: 'POPLEADS.MENU_OVERVIEW' })}</h2>
                        <div className="">
                            <p>  {intl.formatMessage({ id: 'POPLEADS.NO_CONTENT' })}</p>
                        </div>
                    </div>

                }

                {currentLanguage && currentLanguage.lang !== "en" && isEmpty &&

                    <div className='myCourse_courseOverview'>
                        <h2 className='long'>{intl.formatMessage({ id: 'POPLEADS.MENU_OVERVIEW' })}</h2>
                        <div className="">
                            <p>  {intl.formatMessage({ id: 'POPLEADS.NO_CONTENT' })}</p>
                        </div>
                    </div>
                }
            </div>
            <div className='col-lg-1'></div>
            <div className='col-lg-5 columnRight'>
                <img
                    id='illusRight'
                    alt=''
                    src={imageUrl ? imageUrl : toAbsoluteUrl('/media/fallback/coursedetails.png')}
                />
                {/* <FAQ /> */}
            </div>
            {/*  <div className='col-lg-12'>
                <div className='trainerContainer d-flex'>
                    <div className='row'>
                        <div className='col-md-5 d-flex align-items-end'>

                            <div className='trainerName'>Trainer<br />Martha Megan</div>
                            <img
                                alt='Trainer'
                                src={toAbsoluteUrl('/media/stock/trainer.png')}
                            />
                        </div>
                        <div className='col-md-7 d-flex align-items-end'>
                            <p>
                                “Lorem Ipsum is simply dummy text.”
                                <br />
                                <br />
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                            </p>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export { MyCourseDetailOverview }
