/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {ResetPassword} from './components/ResetPassword'
import {Login} from './components/Login'
import {useIntl} from 'react-intl'
import {useLang, setLanguage} from '../../../_metronic/i18n/Metronici18n'
import {languages} from '../../../_metronic/partials/layout/header-menus/Languages'
import {ConfigDataContext} from '../context/configDataContext'

export function AuthPage() {
  const intl = useIntl()
  const lang = useLang()
  const {configData} = useContext(ConfigDataContext)

  const currentLanguage = languages.find((x) => x.lang === lang)
  const html = document.getElementsByTagName('html')[0]
  if (currentLanguage && currentLanguage.lang === 'ar') html.setAttribute('dir', 'rtl')

  let logoUrl = "";
  let welcomeTitle = "";
  let welcomeSubtitle = ""; 
  let helpEmail = "";

  if(configData){
    if(currentLanguage && currentLanguage.lang === 'ar'){
      logoUrl = configData.imageUrls?.ar?.verticalLogo
      welcomeTitle = configData.texts?.ar?.loginWelcomeTitle
      welcomeSubtitle = configData.texts?.ar?.loginWelcomeSubtitle
    } else {
      logoUrl = configData.imageUrls?.en?.verticalLogo
      welcomeTitle = configData.texts?.en?.loginWelcomeTitle
      welcomeSubtitle = configData.texts?.en?.loginWelcomeSubtitle
    }
    helpEmail = configData.texts?.helpEmail
  }

  const isMultiLanguage = configData && configData.languages?.includes("Arabic")

  return (
    <div className='login-page d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='container d-flex align-items-center flex-column-fluid py-10'>
        <div className='row flex-1'>
          <div className='login-image col-xl-7 d-flex justify-content-center'>
            <div className='d-flex flex-column align-items-center' style={{maxWidth: '600px'}}>
              {configData?.imageUrls?.loginPageBgPattern && (
                <img
                  src={configData.imageUrls.loginPageBgPattern}
                  alt='oscho-background'
                  width='100%'
                  height='500'
                  className='background'
                />
              )}
              {logoUrl && (
                <div 
                  className='d-flex justify-content-center align-items-center' 
                  style={{width: "330px", height: "180px"}}
                >
                  <img 
                    alt='Logo' 
                    src={logoUrl} 
                    style={{maxWidth: "100%", maxHeight: "100%"}}
                    // src={toAbsoluteUrl(`/media/logos/${logo}`)} 
                  />
                </div>
              )}
              <h1 className='login-message mt-6 text-center'>
                {welcomeTitle ? welcomeTitle : intl.formatMessage({id: 'POPLEADS.LEARNING_PLATFORM_N'})}
              </h1>
              <p className='login-gray-message mt-2 text-center'>
                {welcomeSubtitle ? welcomeSubtitle : intl.formatMessage({id: 'POPLEADS.LEARNING_PLATFORM_LOGIN'})}
              </p>
            </div>
          </div>
          <div className='col-xl-5 flex-column'>
            <div className='login-box'>
              <Switch>
                <Route path='/auth/login' component={Login} />
                <Route path='/auth/registration' component={Registration} />
                <Route path='/auth/forgot-password' component={ForgotPassword} />
                <Route path='/auth/reset-password' component={ResetPassword} />
                <Redirect from='/auth' exact={true} to='/auth/login' />
                <Redirect to='/auth/login' />
              </Switch>
            </div>
            <div className='col-xl-12 flex-column '>
              <div className='login-footer fs-6 d-flex flex-row justify-content-between'>
                <div className='left'>
                  Popleads © {new Date().getFullYear()} 
                  {helpEmail && (
                    <>
                      <span>{' | '}</span>
                      <a href={"mailto:" + helpEmail}>
                        {intl.formatMessage({id: 'POPLEADS.HELP'})}
                      </a>
                    </>
                  )}
                </div>
                {isMultiLanguage && (
                  <div className='changeLanguageAuth'>
                    {currentLanguage && currentLanguage.lang !== 'en' ? (
                      <span
                        onClick={() => {
                          setLanguage('en')
                        }}
                      >
                        English
                      </span>
                    ) : (
                      <span
                        onClick={() => {
                          setLanguage('ar')
                        }}
                      >
                        العربية
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
