/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useContext} from 'react'
import {useLang} from '../../../_metronic/i18n/Metronici18n'
import {languages} from '../../../_metronic/partials/layout/header-menus/Languages'
import {ConfigDataContext} from '../../modules/context/configDataContext'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'

const Landing: FC = () => {
  const intl = useIntl()
  const lang = useLang()
  const {configData} = useContext(ConfigDataContext)

  const currentLanguage = languages.find((x) => x.lang === lang)
  const html = document.getElementsByTagName('html')[0]
  if (currentLanguage && currentLanguage.lang === 'ar') html.setAttribute('dir', 'rtl')

  let welcomeImgUrl = ''
  let welcomeTitle = ''
  let welcomeSubtitle = ''

  if (configData) {
    welcomeImgUrl = configData?.imageUrls?.allCoursesPageImg
    if (currentLanguage && currentLanguage.lang === 'ar') {
      welcomeTitle = configData.texts?.ar?.allCoursesWelcomeTitle
      welcomeSubtitle = configData.texts?.ar?.allCoursesWelcomeSubtitle
    } else {
      welcomeTitle = configData.texts?.en?.allCoursesWelcomeTitle
      welcomeSubtitle = configData.texts?.en?.allCoursesWelcomeSubtitle
    }
  }

  return (
    <div className='landing'>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-lg-7 col-xl-6 d-flex flex-column'>
            <h1 className='mx-auto mx-lg-0 text-center text-lg-start'>
              {/* <div className='d-flex'>
                            <span className='wel-text me-3 me-lg-5'>{intl.formatMessage({ id: 'POPLEADS.WELCOME_TO' })}</span>
                            <img     
                                alt='Logo'
                                src={toAbsoluteUrl('/media/logos/xxx.png')}
                                className='logo d-none d-lg-inline mb-3'
                                width="140"
                            />
                            <span className='d-inline d-lg-none'> MaSa </span>
                        </div>
                        <span>{intl.formatMessage({ id: 'POPLEADS.LEARNING_PLATFORM' })}</span> */}
              {welcomeTitle ? welcomeTitle : intl.formatMessage({id: 'POPLEADS.WELCOME'})}
            </h1>
            <p className='login-gray-message mt-5 text-center text-lg-start'>
              {welcomeSubtitle
                ? welcomeSubtitle
                : intl.formatMessage({id: 'POPLEADS.LEARNING_PLATFORM_HOME'})}
            </p>

            {/* <div className='marketIcons'>
                        <a href='https://play.google.com/store/apps/details?id=com.popleads.ta3leembusiness&hl=en_SG&gl=US' target="_blank">
                            {isRTL ?
                                <KTSVG
                                    path='/media/svg/popleads/googlePlay_RTL.svg'
                                    className='marketIcon'
                                />
                                :
                                <KTSVG
                                    path='/media/svg/popleads/googlePlay.svg'
                                    className='marketIcon'
                                />
                            }
                        </a>
                        <a href="https://apps.apple.com/us/app/ta3leem-for-business/id1500897240" target="_blank">
                            {isRTL ?
                                <KTSVG
                                    path='/media/svg/popleads/appStore_RTL.svg'
                                    className='marketIcon'
                                />
                                :
                                <KTSVG
                                    path='/media/svg/popleads/appStore.svg'
                                    className='marketIcon'
                                />
                            }

                        </a>
                    </div> */}
          </div>
          <div className='col-lg-5 px-6 d-flex justify-content-center'>
            <img
              id='illusRight'
              alt=''
              src={welcomeImgUrl ? welcomeImgUrl : toAbsoluteUrl('/media/fallback/homepage.png')}
              className='mt-5 mt-lg-0'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Landing}
